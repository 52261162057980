//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import createRechargeOrder from "@/lib/data-service/default/wallet_order_createRechargeOrder";
import queryAccountDetail from "@/lib/data-service/default/wallet_account_queryAccountDetail";
export default {
  name: "account-deposit",
  data() {
    return {
      fromData: {},
      rule: {},
      detail: null
    };
  },
  methods: {
    next() {
      if (!this.fromData.dealAmount || this.fromData.dealAmount == 0) return;
      // if (this.fromData.dealAmount)
      createRechargeOrder(this.fromData).then(res => {
        let orderId = res.orderId;
        this.$router.push({
          name: "distributor-account-pay",
          query: { orderId }
        });
      });
    },
    ruleNumber(val) {
      if (!val) return;
      this.fromData.dealAmount = val.match(/^[0-9]+\.?\d{0,2}/)
        ? val.match(/^[0-9]+\.?\d{0,2}/)[0]
        : 1;
    },
    getBalance() {
      queryAccountDetail().then(res => {
        this.detail = res;
      });
    }
  },
  activated() {
    this.fromData = {};
    this.getBalance();
  }
};
